<template>
  <div class="pur-rec scroll-box" @scroll="scrollBox($event)">
    <TopBar :type="2" :pageTitle="'我的学分'" /><span style="display: block;text-align: center;font-size: 20px;">总分：{{ total_credit }}</span>
    <ul class="list">
      <li class="info" v-for="item in orderList" :key="item.id">
        <div class="content">
          <p class="name vertical-ellipsis">标题：{{item.title}}</p>
          <p class="name vertical-ellipsis">学分数：{{item.number}}</p>
        </div>
        <span style="color: #999;display: block;margin-top: 10px;">{{item.created_at}}</span>
      </li>
    </ul>
    <div class="more" v-show="isMore">{{moreText}}</div>
    <div class="more bottom-line" v-show="!isMore && orderList.length != 0">我是有底线的</div>
    <div class="nodata" v-if="noData">
      <img class="nodata-img" :src="require('@/assets/no-data.png')">
      <p class="nodata-text">暂无详细记录</p>
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
export default {
  name: 'PurchaseRecords',
  data(){
    return {
      noData: false,
      orderList: [],  //记录
      page: 1,        //当前页
      pre_page: 10,   //一页显示几条
      isMore: false,
      moreText: '查看更多',
      total_credit: 0
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.getOrderList(); //获取记录
  },
  methods: {
    //获取记录
    getOrderList(){
      this.moreText = '加载中…';
      this.$axios.post(`/v1/personal/creditRecord`, {
        page: this.page,
        pre_page: this.pre_page,
      },
      {useLog: true}).then(res => {
        this.orderList = [...this.orderList, ...res.data.record.data];
        if(this.orderList.length == 0){
          this.noData = true;
        }
        this.moreText = '查看更多';
        this.total_credit = res.data.total_credit
        if(this.orderList.length < res.data.record.total && this.orderList.length!=0){
          this.isMore = true;
        }else{
          this.isMore = false;
        }
      })
    },
    scrollBox(e) {
      if(e.target.scrollHeight-e.target.clientHeight == e.target.scrollTop && this.isMore){
        this.page ++;
        this.getOrderList();
      }
    },
  }
}
</script>

<style scoped lang="scss">
.list{
  padding: 28px;
}
.info{
  padding: 28px;
  box-shadow: 0px 0px 10px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  margin-bottom: 28px;
}
.title{
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  color: #666;
  margin-bottom: 14px;
  .date{
    font-size: 24px;
    color: #999;
  }
}
.content{
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  font-size: 28px;
  .info-img{
    width: 242px;
    height: 134px;
    border-radius: 6px;
    float: left;
  }
  .content-right{
    width: 376px;
    height: 134px;
    float: right;
    position: relative;
    .name{
      max-height: 76px;
      line-height: 38px;
      font-size: 28px;
      color: #333;
    }
    .price{
      font-size: 36px;
      color: #CD1B14;
      font-weight: bold;
      position: absolute;
      left: 0;
      bottom: 0;
      .unit{
        font-size: 22px;
        font-weight: normal;
      }
    }
  }
}
.more{
  padding-right: 30px;
  font-size: 28px;
  color: #999;
  margin: 0 auto 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after{
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    background: url(~@/assets/arrows-down-ico.png) center center no-repeat;
    background-size: 100% auto;
    margin-left: 8px;
  }
  &.bottom-line{
    padding-right: 0;
    &::after{
      display: none;
    }
  }
}
.nodata{
  text-align: center;
  .nodata-img{
    width: 576px;
    height: auto;
  }
}
</style>
